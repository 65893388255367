import * as _ from 'lodash';

const UNDEFINED = 'UNDEFINED'

const ID = 'ID'
const TEXT = 'TEXT'
const TEXTAREA = 'TEXTAREA'
const DATETIME = 'DATETIME'
const DATE = 'DATE'
const NUMBER = 'NUMBER'
const OBJECT = 'OBJECT'
const NAMEKEY = 'NAMEKEY'
const ICON = 'ICON'
const TAG = 'TAG'
const META = 'META'
const ACTIONTYPEADJUSTMENTFACTORS = 'ACTIONTYPEADJUSTMENTFACTORS'
const CUSTOM = 'CUSTOM'
const CONSTRAINT = 'CONSTRAINT'
const SELECT = 'SELECT'
const MEMBERIDS = 'MEMBERIDS'
const ENTITYREFIDS = 'ENTITYREFIDS'
const AWARDSIDS = 'AWARDSIDS'
const TRANSFORMERIDS = 'TRANSFORMERIDS'
const HOSTINGOPTIONS = 'HOSTINGOPTIONS'
const REWARDTYPEIDS = 'REWARDTYPEIDS'
const TRIGGERS = 'TRIGGERS'
const REWARDENTITYTYPE = 'REWARDENTITYTYPE'
const UNITOFMEASURE = 'UNITOFMEASURE'
const INSTANTWINTYPE = 'INSTANTWINTYPE'
const WALLETTYPEIDS = 'WALLETTYPEIDS'

const isPrimitiveType = 'isPrimitiveType'
const isContainer = 'isContainer'
const isMap = 'isMap'
const isString = 'isString'
const isDateTime = 'isDateTime'
const isDate = 'isDate'
const isNumeric = 'isNumeric'
const isDouble = 'isDouble'
const isInteger = 'isInteger'
const isShort = 'isShort'
const isLong = 'isLong'
const isArray = 'isArray'
const isModel = 'isModel'
const isEnum = 'isEnum'
const isConstraintsArray = 'isConstraintsArray'
const required = 'required'

const knownUniqueIdentifierFields = ['id']
const knownProductFields = ['productIds']
const knownEntityRefIdFields = ['entityRefId']
const knownTextAreaFields = ['description','termsAndConditions']
const knownCustomFields = ['customFields']
const knownConstraintFields = ['constraints','addConstraints','removeConstraints']
const knownIconFields = ['icon', 'banner', 'bannerHighResolution', 'bannerLowResolution']
const knownNameKeyFields = []
const knownMemberIdsFields = ['memberId', 'memberRefId', 'memberIds']
const knownRewardTypeIdFields = ['rewardTypeId']
const knownWalletTypeIdFields = ['walletTypeId']
const knownAwardsIdsFields = ['prize']
const knownTransformersIdsFields = ['transformerId']
const knownActionTypeAdjustmentFactors = ['actionTypeAdjustmentFactors']
const knownHostingOptionsFields = ['hostingOptions']
const knownTriggersFields = ['triggers']
const knownSelectFields = ['addRoles', 'removeRoles', 'teamMembers']
const knownRewardEntityTypeFields = ['entityType']
const knownUnitOfMeasureFields = ['unitOfMeasure']
const knownInstantWinTypeFields = ['instantWinType']
const knownEntityTypes = [
    'Product',
    'Member',
    'Achievement',
    'Repository',
    'FileObject',
    'Language',
    'MemberMessage',
    'Reward',
    'MemberAward',
    'Award',
    'Message',
    'RewardType',
    'ActionType',
    'UnitOfMeasure',
    'Competition',
    'Contest',
    'SqsConnection',
    'KafkaConnection',
    'RabbitMqConnection',
    'Events',
    'Webhook',
    'Attachment',
    'RuleSet',
    'Connection',
    'InstantWin'
]
const knownRewardEntityTypes = [
    'Achievement',
    'Competition',
    'Contest',
]
const labelKeyPostFix = 'Label'
let valueFields = {}
const modelsWithoutConstraints = ['ActionType']
const modelsWithoutNames = ['Language', 'UnitOfMeasure']
const modelsWithoutTags = ['UpdateLanguageRequest']
const modelsWithoutEntityId = ['Reward']
const requestsWithoutConstraints = ['CreateActionTypeRequest']
const requestsWithoutEntityTypeFields = ['CreateEntityRewardRequest']
const knownReadOnlyFields = ['id', 'appliesTo', 'fieldType']

const preparePreviewFormList = (storeFields, labels, toolTips) => {
    return prepareFormList(storeFields.baseFields, labels, toolTips, true)
}

const prepareCreateFormList = (storeFields, labels, toolTips) => {
    return prepareFormList(
        copyParamsFromBase(storeFields.baseFields,storeFields.createFields), labels, toolTips, false
    )
}

const prepareEditFormList = (storeFields, labels, toolTips, valueProduct) => {
    valueFields = valueProduct
    const readOnlyFields = _.difference(storeFields.baseFields.fieldList, storeFields.updateFields.fieldList);
    const fieldsNotInBase = _.difference(storeFields.updateFields.fieldList, storeFields.baseFields.fieldList);

    if (modelsWithoutTags.includes(storeFields.updateFields.objectType)) {
        delete storeFields.baseFields.fields.tags
    }

    if (modelsWithoutConstraints.includes(valueProduct.constructor.name)) {
        delete storeFields.updateFields.fields.addConstraints
        delete storeFields.updateFields.fields.removeConstraints
    }

    if (valueProduct.constructor.name === 'Achievement') {
        delete storeFields.updateFields.fields.achievementLiveStatus;
    }

    if ('teamMembers' in valueProduct) {
        delete storeFields.updateFields.fields.teamMembers;
        delete storeFields.baseFields.fields.teamMembers;

        storeFields.baseFields.fields.memberRefId.isUpdate = true
    }

    if (modelsWithoutNames.includes(valueProduct.constructor.name)) {
        delete storeFields.baseFields.fields.name
    }

    if (modelsWithoutEntityId.includes(valueProduct.constructor.name)) {
        delete storeFields.baseFields.fields.entityId
    }

    let mergedFields = Object.values(storeFields.baseFields.fields)

    for (const updateField of Object.values(storeFields.updateFields.fields)) {
        if(fieldsNotInBase.includes(updateField.name)){
            mergedFields.push(updateField)
        }
    }

    const orderedFields = orderFieldsByDisplayOrder(mergedFields)
    let formList = []

    for (const field of orderedFields) {
        let disabled = readOnlyFields.includes(field.name) || knownReadOnlyFields.includes(field.name)
        const formListItem = prepareFormListItem(field, storeFields.baseFields, labels, toolTips, disabled)

        if(formListItem.type === ID){
            formListItem.disabled = true
        }

        if(!readOnlyFields.includes(formListItem.key)){
            formListItem.model = storeFields.updateFields.objectType
        }

        if (formListItem.type === NAMEKEY) {
            formListItem.value = {key: valueProduct.key, name: valueProduct.name}
        }

        if (formListItem.type === REWARDENTITYTYPE) {
            formListItem.value = {entityId: valueProduct.entityId, entityType: valueProduct.entityType}
        }

        formList.push(formListItem)
    }

    if (formList.findIndex(item => item.type === 'NAMEKEY') !== -1) {
        formList = formList.filter(item => item.key !== 'name')
    }

    return formList
}

const prepareFormList = (fieldsDescriptor, labels, toolTips, disabled) => {
    if (fieldsDescriptor.objectType === 'CreateLanguageRequest') {
        delete fieldsDescriptor.fields.tags
    }

    if (requestsWithoutConstraints.includes(fieldsDescriptor.objectType)) {
        delete fieldsDescriptor.fields.addConstraints
    }

    if (fieldsDescriptor.objectType === 'CreateMemberRequest') {
        delete fieldsDescriptor.fields.teamMembers;

        fieldsDescriptor.fields.memberRefId.isCreateMember = true;
    }

    if (fieldsDescriptor.objectType === 'CreateNotificationRequest'
      || fieldsDescriptor.objectType === 'CreateMemberMessageRequest') {
        delete fieldsDescriptor.fields.expireAfterDate
    }

    const orderedFields = orderFieldsByDisplayOrder(Object.values(fieldsDescriptor.fields))

    if (requestsWithoutEntityTypeFields.includes(fieldsDescriptor.objectType)) {
        const entityIdIdx = orderedFields.findIndex(field => field.name === 'entityId')
        if (entityIdIdx !== 1) {
            orderedFields.splice(entityIdIdx, 1)
        }
    }

    const nameIdx = orderedFields.findIndex(field => field.name === 'name')
    let tagsIdx = orderedFields.findIndex(field => field.name === 'tags')
    if (nameIdx !== -1 && tagsIdx !== -1) {
        if (nameIdx !== 0) {
            orderedFields.splice(0, 0, orderedFields.splice(nameIdx, 1)[0])
        }
        tagsIdx = orderedFields.findIndex(field => field.name === 'tags')
        if (fieldsDescriptor.objectType && fieldsDescriptor.objectType === 'CreateMemberRequest') {
            orderedFields.splice(3, 0, orderedFields.splice(tagsIdx, 1)[0])
        } else {
            orderedFields.splice(1, 0, orderedFields.splice(tagsIdx, 1)[0])
        }

        const keyIdx = orderedFields.findIndex(field => field.name === 'key')
        if (keyIdx !== -1) {
            orderedFields.splice(0, 0, orderedFields.splice(keyIdx, 1)[0])
        }
    }

    valueFields = {}
    let formList = []

    for (const field of orderedFields) {
        formList.push(prepareFormListItem(field, fieldsDescriptor, labels, toolTips, disabled))
    }

    if (formList.findIndex(item => item.type === 'NAMEKEY') !== -1) {
        formList = formList.filter(item => item.key !== 'name')
    }

    return formList
}

const prepareFormListItem = (field, fieldsDescriptor, labels, toolTips, disabled) => {
    const name = field.name
    const formFieldType = getFormBuilderTypeFromField(fieldsDescriptor, field)
    const labelKey = name + labelKeyPostFix
    const options = getAllowableValues(formFieldType, field)
    const value =  assignmentValue(valueFields, name)
    let showKey = false
    let isCreate = false
    let isUpdate = false

    if (formFieldType === NAMEKEY && field.formType.split(', ').length > 1) {
        const fieldKey = field.formType.split(', ')[1]
        if (fieldKey.indexOf('showKey') !== -1 && fieldKey.indexOf('true') !== -1) {
            showKey = true
        }
    }
    if (formFieldType === MEMBERIDS ) {
        if (field.isCreateMember) {
            isCreate = true
        }

        if (field.isUpdate) {
            isUpdate = true
        }
    }

    return asFormListItem (
        name,
        (labels && labels[labelKey]) ? labels[labelKey] : name,
        (toolTips && toolTips[name]) ? toolTips[name] : '',
        formFieldType,
        fieldsDescriptor.objectType,
        value,
        disabled,
        field,
        options,
        field.constraints.includes(isArray),
        field.constraints.includes(required),
        showKey,
        isCreate,
        isUpdate
    )
}

const assignmentValue = (valueFields, name) => {
    const keys = Object.keys(valueFields);
    let result = null
    keys.forEach(key => {
        if (key === name) {
            result = valueFields[key];
        }
    });

    return result
}

const getFormBuilderTypeFromField = (fieldsDescriptor, field) => {
    let c = field.constraints

    /// Schema defined ///
    if (field.formType) {
        if (field.formType.indexOf('NAMEKEY') !== -1) {
            return NAMEKEY
        } else {
           return field.formType
        }
    }

    /// Icon ///
    else if (knownIconFields.includes(field.name)) {
        return ICON
    }

    // InstantWinType
    else if (knownInstantWinTypeFields.includes(field.name)) {
        return INSTANTWINTYPE
    }

    /// Tag ///
    else if(fieldsDescriptor.tagFieldName.includes(field.name)){
        return TAG
    }

    /// Meta ///
    else if(fieldsDescriptor.metadataFieldName.includes(field.name)){
        return META
    }

    else if (containsAll(c,[isPrimitiveType,isContainer,isMap, isString, ])) {
        // We can safely assume this is a META field
        return META
    }

    else if (knownActionTypeAdjustmentFactors.includes(field.name)) {
        return ACTIONTYPEADJUSTMENTFACTORS
    }

    else if (knownMemberIdsFields.includes(field.name)) {
        return MEMBERIDS
    }

    else if (knownEntityRefIdFields.includes(field.name)) {
        return ENTITYREFIDS
    }

    else if (knownRewardTypeIdFields.includes(field.name)) {
        return REWARDTYPEIDS
    }

    else if (knownWalletTypeIdFields.includes(field.name)) {
        return WALLETTYPEIDS
    }

    else if (knownAwardsIdsFields.includes(field.name)) {
        return AWARDSIDS
    }

    else if (knownTransformersIdsFields.includes(field.name)) {
        return TRANSFORMERIDS
    }

    /// Custom Fields ///
    else if(fieldsDescriptor.customFieldsFieldName.includes(field.name)){
        return CUSTOM
    }
    else if(knownCustomFields.includes(field.name)){
        return TEXTAREA
    }

    /// Name key Fields ///
    else if(knownNameKeyFields.includes(field.name)){
        return NAMEKEY
    }

    /// ID ///
    else if (knownUniqueIdentifierFields.includes(field.name)) {
        return ID
    }

    else if(knownProductFields.includes(field.name)){
        return 'PRODUCTS' + '_' + OBJECT
    }

    else if(knownHostingOptionsFields.includes(field.name)){
        return HOSTINGOPTIONS
    }

    else if(knownTriggersFields.includes(field.name)){
        return TRIGGERS
    }

    else if (knownSelectFields.includes(field.name)) {
        return SELECT
    }

    else if (knownRewardEntityTypeFields.includes(field.name)) {
        return REWARDENTITYTYPE
    }

    else if (knownUnitOfMeasureFields.includes(field.name)) {
        return UNITOFMEASURE
    }

    /// Date & Time ///
    else if (containsAny(c,[isDateTime])) {
        return DATETIME
    }

    /// Date ///
    else if (containsAny(c,[isDate])) {
        return DATE
    }
    else if (containsAny(c,[isNumeric,isDouble,isInteger,isShort,isLong])) {
        return NUMBER
    }

    /// Constraint ///
    else if(containsAny(c,[isConstraintsArray]) || knownConstraintFields.includes(field.name)){
        return CONSTRAINT
    }

    /// Text & Text Area ///
    else if (containsAll(c,[isString,isPrimitiveType])) {

        if(knownTextAreaFields.includes(field.name)){
            return TEXTAREA
        }
        else {
            return TEXT
        }
    }

    /// Object ///
    else if (containsAny(c,[isModel])) {
        return field.complexType.objectType.toUpperCase() + '_' + OBJECT
    }

    /// Select ///
    else if(field.complexType && containsAny(field.complexType.constraints, [isEnum])){
        return SELECT // Options are the at -> field.complexType.constraints.allowableValuesKeys [Example: CompetitionsZq -- status -- complexType]
    }

    ////// No idea what this is, go ask an adult //////
    else {
        return UNDEFINED
    }
}

/// Helper functions ///


const copyParamsFromBase = (base, ack) => {
    for (const field of Object.values(ack.fields)) {
        if (base.fields.hasOwnProperty(field.name)) {
            field.allowableValuesKeys =
                (field.allowableValuesKeys && field.allowableValuesKeys.length > 0)
                ? field.allowableValuesKeys
                : base.fields[field.name].allowableValuesKeys

            field.displayOrder =
                (field.displayOrder && field.displayOrder < 2000)
                ? field.displayOrder
                : base.fields[field.name].displayOrder
        }
    }
    return ack
}

const getAllowableValues = (formFieldType, field) => {
    let options = null

    if (formFieldType === CONSTRAINT) {
        options = field.allowableValuesKeys
    } else if (field.complexType && containsAny(field.complexType.constraints, [isEnum])){
        options = field.complexType.allowableValuesKeys

        if (formFieldType === REWARDENTITYTYPE) {
            options = knownRewardEntityTypes
        }
        if (field.name === 'role') {
            options = field.complexType.allowableValuesKeys.filter(opt => opt.toLowerCase() !== 'owner')
        }
    } else if (field.name === 'entityTypes') {
        options = knownEntityTypes
    } else if (formFieldType === SELECT) {
        options = field.allowableValuesKeys
    }
    return options
}

const orderFieldsByDisplayOrder = (fieldsArray) => {
    return fieldsArray.sort(function (a, b) {
        const keyA = a.displayOrder;
        const keyB = b.displayOrder;

        const requiredA = a.constraints.includes(required);
        const requiredB = b.constraints.includes(required);

        if (requiredA && !requiredB) return -1;
        if (requiredB && !requiredA) return 1;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
    });
}

const asFormListItem = (
    key,
    label,
    tooltip,
    type,
    model,
    value,
    disabled,
    fieldDescriptor,
    options,
    multiSelect,
    required,
    showKey = false,
    isCreate = false,
    isUpdate = false
) => {
    return {
        key: key,
        label: label,
        tooltip: tooltip,
        type: type,
        model: model,
        value: value,
        disabled: disabled,
        fieldDescriptor: fieldDescriptor,
        options: options,
        multiple: multiSelect,
        required: required,
        showKey: showKey,
        isCreate: isCreate,
        isUpdate: isUpdate
    }
}

const containsAll = (constraints, find) => {
    let count = find.length
    find.forEach((toFind) => {
        if(constraints.includes(toFind)){
            count = count - 1
        }
    })
    return count <= 0
}

const containsAny = (constraints, find) => {
    let found = false
    find.forEach((toFind) => {
        if(constraints.includes(toFind)){
            found = true
        }
    })
    return found
}

const notContainsAny = (constraints, find) => {
    let found = true
    find.forEach((toFind) => {
        if(constraints.includes(toFind)){
            found = false
        }
    })
    return found
}


export default {
    getFormBuilderTypeFromField,
    preparePreviewFormList,
    prepareCreateFormList,
    prepareEditFormList
}
